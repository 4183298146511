import React from 'react';

import ConfirmationDialog from '@au/core/lib/components/objects/ConfirmationDialog';

import { redirectOnSaveSuccess } from '../../../utils/linkHelper';
import { findClientsByIds } from '../../../utils/accounts';
import { history as browserHistory } from '../../../history';
import DefaultEdit from '../Edit';

export default class UserGroupEdit extends DefaultEdit {
  
  componentDidMount() {
    super.componentDidMount();

    const { location } = this.props;
    const { showMembershipFailureDialog } = location?.state ?? {};

    this.setState({ showMembershipFailureDialog });

    // clear location state
    browserHistory.replace({ state: {} });
  }

  async loadData() {        
    await super.loadData();
    
    const { endpoint, match } = this.props;
    const { entityId } = match.params;

    this.usersEndpoint = endpoint.getUsersEndpoint(entityId);
    this.clientsEndpoint = endpoint.getClientsEndpoint(entityId);

    const users = await this.usersEndpoint.list().then(resp => resp.items, this.genericErrorHandler);
    const usersClient = await this.clientsEndpoint.list().then(resp => resp.items, this.genericErrorHandler);
    const ids = usersClient.map(user => user.username.split("service-account-").pop());
    
    let clients = [];
    if (ids.length > 0) {
      clients = await findClientsByIds(ids).then(res => res.items, this.genericErrorHandler);
    }

    this.setState(prevState => ({
      entity: prevState.entity.withMutations(entity => {
        entity.set('users', users || []);
        entity.set('clients', clients || []);
      })
    }));
  }

  getEntitiesToCreate() {
    const { serviceAlias, entityDef, match } = this.props;
    const { modifiedEntity, entity } = this.state;
    const { entityAlias } = match.params;
    
    const entitiesToCreate = super.getEntitiesToCreate();

    const didUserGroupEntityChange = Object.keys(entityDef.attributes)
        .some(key => (modifiedEntity[key] !== entity.get(key)) && (key !== 'users' && key !== 'clients' && key !== 'tags'));

    let usersChange = false;
    let clientsChange = false;

    let usersQueuedForDeletion = modifiedEntity.users?.filter(
      user => user.toBeDeleted && entity.get('users')?.some(oldUser => oldUser.id === user.id)
    ) || [];
    let usersQueuedForAddition = modifiedEntity.users?.filter(
      user => !user.toBeDeleted && !entity.get('users')?.some(oldUser => oldUser.id === user.id)
    ) || [];

    let clientsQueuedForDeletion = modifiedEntity.clients?.filter(
      client => client.toBeDeleted && entity.get('clients')?.some(oldClient => oldClient.id === client.id)
    ) || [];
    let clientsQueuedForAddition = modifiedEntity.clients?.filter(
      client => !client.toBeDeleted && !entity.get('clients')?.some(oldClient => oldClient.id === client.id)
    ) || [];

    if (this.createMode == false) {
      if (usersQueuedForDeletion.length > 0 || usersQueuedForAddition.length > 0) {
        usersChange = true;
      }
      if (clientsQueuedForDeletion.length > 0 || clientsQueuedForAddition.length > 0) {
        clientsChange = true;
      }
    }

    if (didUserGroupEntityChange) {
      // add our main entity
      entitiesToCreate.set(`${serviceAlias}-${entityAlias}`, {
        entityType: entityDef.type,
        entities: [{
          key: `${serviceAlias}-${entityAlias}-new`,
          idProp: entityDef.pkField,
          data: { ...modifiedEntity },
          saveFn: this.createMode ? this.saveUserGroupEntity : this.updateUserGroupEntity,
          halt: true,
          displayFields: ['name', 'displayName', 'description']
        }],
        verbRoot: this.createMode ? 'create' : 'edit',
        serviceAlias,
        entityAlias
      });
    }

    if (usersChange) {
      entitiesToCreate.set('accounts-userGroups-members', {
        entityType: 'user',
        entities: [{
          key: 'accounts-service-userGroups-members-new',
          idProp: entityDef.pkField,
          data: {},
          saveFn: this.createMode ? this.saveUserGroupEntity : this.updateUserGroupEntity,
          halt: true,
          displayFields: []
        }],
        verbRoot: this.createMode ? 'create' : 'edit',
        serviceAlias: 'accounts',
        entityAlias: 'userGroups'
      })
    }

    if (clientsChange) {
      entitiesToCreate.set('accounts-userGroups-clients', {
        entityType: 'client',
        entities: [{
          key: 'accounts-service-userGroups-clients-new',
          idProp: entityDef.pkField,
          data: {},
          saveFn: this.createMode ? this.saveUserGroupEntity : this.updateUserGroupEntity,
          halt: true,
          displayFields: []
        }],
        verbRoot: this.createMode ? 'create' : 'edit',
        serviceAlias: 'accounts',
        entityAlias: 'userGroups'
      })
    }
    

    return entitiesToCreate;
  }

  // for edit page
  updateUserGroupEntity = this.updateUserGroupEntity.bind(this)
  updateUserGroupEntity(userGroup) {
    const { endpoint, entityDef } = this.props;
    const { modifiedEntity, entity} = this.state;
    
    const requests = [];

    const userGroupChange = Object.keys(entityDef.attributes)
        .some(key => (modifiedEntity[key] !== entity.get(key)) && (key !== 'users' && key !== 'clients' && key !== 'tags'));

    if (userGroupChange) {
      requests.push(endpoint.patch(userGroup));
      this.setState({userGroupChange: true});
    } else {
      // fake a response as we don't need to update the user group itself
      requests.push(Promise.resolve({ data: modifiedEntity }));
      this.setState({userGroupChange: false});
    }

    let usersQueuedForDeletion = modifiedEntity.users?.filter(
      user => user.toBeDeleted && entity.get('users')?.some(oldUser => oldUser.id === user.id)
    ) || [];
    let usersQueuedForAddition = modifiedEntity.users?.filter(
      user => !user.toBeDeleted && !entity.get('users')?.some(oldUser => oldUser.id === user.id)
    ) || [];
    usersQueuedForDeletion.forEach(user => requests.push(this.usersEndpoint.delete(user)));
    usersQueuedForAddition.forEach(user => requests.push(this.usersEndpoint.create(user)));
    if (usersQueuedForAddition.length > 0 || usersQueuedForDeletion.length > 0) {
      this.setState({usersChange: true});
    }

    let clientsQueuedForDeletion = modifiedEntity.clients?.filter(
      client => client.toBeDeleted && entity.get('clients')?.some(oldClient => oldClient.id === client.id)
    ) || [];
    let clientsQueuedForAddition = modifiedEntity.clients?.filter(
      client => !client.toBeDeleted && !entity.get('clients')?.some(oldClient => oldClient.id === client.id)
    ) || [];
    clientsQueuedForDeletion.forEach(client => requests.push(this.clientsEndpoint.delete(client.subjectAui.replace('aui:iam:user/', ''))));
    clientsQueuedForAddition.forEach(client => requests.push(this.clientsEndpoint.create(client)));
    if (clientsQueuedForAddition.length > 0 || clientsQueuedForDeletion.length > 0) {
      this.setState({clientsChange: true});
    }

    // We return original object if everything is fine otherwise return the error state
    return Promise.all(requests).then(all => all[0]);
  }

  // for create page
  saveUserGroupEntity = this.saveUserGroupEntity.bind(this)
  saveUserGroupEntity(userGroup) {
    const { endpoint } = this.props;
    const { modifiedEntity } = this.state;

    return endpoint.create(userGroup).then(result => {
      const userGroupId = result.data.id;
      const requests = [];
      
      modifiedEntity.users?.forEach(user => requests.push(
        endpoint.getUsersEndpoint(userGroupId).create(user)
      ));

      modifiedEntity.clients?.forEach(client => requests.push(
        endpoint.getClientsEndpoint(userGroupId).create({ 
          ...client, 
          username: client.subjectAui.replace('aui:iam:user/', '') 
        })
      ));
      
      return [requests, result];
    }).then(params => {
      Promise.allSettled(params[0]).then(values => 
        values.forEach(item => Object.values(item).includes('rejected') ? this.handleSaveRollback(params[1].data.id) : null)
      );
      // Return back original entity
      return params[1];
    }); 
  }

  handleSaveRollback = this.handleSaveRollback.bind(this);
  handleSaveRollback(userGroupId) {
    const { serviceAlias } = this.props;
    browserHistory.push({
      pathname: `/services/${serviceAlias}/userGroups/` + userGroupId + `/edit`,
      state: {
        showMembershipFailureDialog: true,
        userGroupId: userGroupId
      }
    });
  }

  getDisplayIdOverrides() {
    const overrides = new Map();
    overrides.set('displayName', 'au.userGroup.displayName');
    return overrides;
  }

  handleOnSave = this.handleOnSave.bind(this);
  handleOnSave(modifiedEntity) {
    this.setState({ showStatusDialog: true, modifiedEntity });
    return Promise.resolve();
  }

  onStatusDialogClose = this.onStatusDialogClose.bind(this);
  onStatusDialogClose(created, statuses) {
    if (created) {
      const { serviceAlias, match } = this.props;
      const { entityAlias } = match.params;
      let entityVal = entityAlias;
      if (!this.state.userGroupChange) {
        if (this.state.usersChange) {
          entityVal = 'userGroups-members';
        }
        else if (this.state.clientsChange) {
          entityVal = 'userGroups-clients';
        }
      }
      const status = statuses.get(`${serviceAlias}-${entityVal}-new`);

      redirectOnSaveSuccess(status.resp, this.props.endpoint, match.url);
    } else {
      super.onStatusDialogClose();
    }
  }

  renderDialogs() {
    const dialogs = super.renderDialogs();
    dialogs.push(this.renderMembershipFailureDialog());
    return dialogs;
  }

  renderMembershipFailureDialog() {
    const { showMembershipFailureDialog } = this.state;
    
    if (!showMembershipFailureDialog || this.createMode) {
      return false;
    }

    return (
      <ConfirmationDialog
        type="alert"
        titleId="au.membership.userGroup.failureToCreate"
        confirmDisplayId="au.entity.proceed"
        key="ConfirmErrorUserGroup"
        onConfirm={this.handleOnProceed}
        onCancel={this.handleOnProceed}
      />
    );
  }

  handleOnProceed = this.handleOnProceed.bind(this);
  handleOnProceed() {
    const { actions, serviceAlias, location } = this.props;
    this.setState({ showMembershipFailureDialog: false });
    actions.openEntityPage(
      'services', `/services/${serviceAlias}`, 'userGroups', location.state.userGroupId, 'edit'
    );
  }
}
